import { Entity } from '@backstage/catalog-model';
import { get } from 'lodash';

export const hasLinks = (entity: Entity) =>
  Boolean(entity?.metadata?.links?.length);

export const entityHasAnnotation = (entity: Entity, annotation: string) =>
  Boolean(get(entity, ['metadata', 'annotations', annotation]));

export const hasKubernetes = (entity: Entity) =>
  entityHasAnnotation(entity, 'backstage.io/kubernetes-id') ||
  entityHasAnnotation(entity, 'backstage.io/kubernetes-label-selector');

export const hasSonarqube = (entity: Entity) =>
  entityHasAnnotation(entity, 'sonarqube.org/project-key');

export const hasGitHubReleases = (entity: Entity) =>
  entityHasAnnotation(entity, 'github.com/releases');

export const hasCloudflareCache = (entity: Entity) =>
  entityHasAnnotation(entity, 'cloudflare.com/cache-domains');

export const hasTechDoc = (entity: Entity) =>
  entityHasAnnotation(entity, 'backstage.io/techdocs-entity') ||
  entityHasAnnotation(entity, 'backstage.io/techdocs-ref');
