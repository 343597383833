import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  EntityAboutCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityLayout,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import { EntityTechInsightsScorecardCard } from '@backstage-community/plugin-tech-insights';
import {
  RELATION_PART_OF,
  RELATION_HAS_PART,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_PROVIDES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
} from '@backstage/catalog-model';
import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import LinkCards from '../../linkCard/LinkCards';
import { hasLinks } from '../../utils';
import EntityWarningContent from '../components/EntityWarningContent';
import OnboardingButton from '../components/OnboardingButton';

const SystemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {EntityWarningContent}

        <Grid md={6}>
          <EntitySwitch>
            <EntitySwitch.Case if={hasLinks}>
              <Grid xs={12}>
                <EntityAboutCard variant="gridItem" />
                <LinkCards />
              </Grid>
            </EntitySwitch.Case>

            <EntitySwitch.Case>
              <EntityAboutCard variant="gridItem" />
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>

        <Grid md={6} xs={12}>
          <EntityTechInsightsScorecardCard
            title="System onboarding checklist"
            checksId={[
              'groupOwnerCheck',
              'titleCheck',
              'techDocsCheck',
              'systemHasResources',
              'systemHasComponents',
            ]}
          />

          {OnboardingButton}
        </Grid>

        <Grid md={12}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>

        <Grid md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>

        <Grid md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

export default SystemPage;
