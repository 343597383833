import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const cloudflareCacheManagementPlugin = createPlugin({
  id: 'cloudflare-cache-management',
  routes: {
    root: rootRouteRef,
  },
});

export const CloudflareCacheManagementPage =
  cloudflareCacheManagementPlugin.provide(
    createRoutableExtension({
      name: 'CloudflareCacheManagementPage',
      component: () =>
        import('./components/CloudflareEntityPage').then(
          m => m.CloudflareEntityPage,
        ),
      mountPoint: rootRouteRef,
    }),
  );
