import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'helm-dashboard',
});

export const detailsRouteRef = createSubRouteRef({
  id: 'helm-release',
  parent: rootRouteRef,
  path: '/namespaces/:namespace/releases/:release',
});
