import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { LinkButton } from '@backstage/core-components';

const OnboardingButton = (
  <Grid
    sx={{ borderRadius: '0 0 4px 4px', padding: '10px 16px' }}
    justifyContent="flex-end"
  >
    <LinkButton
      to="/docs/default/system/backstagedeveloperportal/"
      title="Onboarding Guide"
    >
      Onboarding Guide
    </LinkButton>
  </Grid>
);

export default OnboardingButton;
