import React from 'react';
import {
  EntityAboutCard,
  EntityHasSystemsCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import Grid from '@mui/material/Unstable_Grid2';
import EntityWarningContent from '../components/EntityWarningContent';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import { EntityTechInsightsScorecardCard } from '@backstage-community/plugin-tech-insights';
import OnboardingButton from '../components/OnboardingButton';

const DomainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {EntityWarningContent}

        <Grid md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>

        <Grid md={6} xs={12}>
          <EntityTechInsightsScorecardCard
            title="Domain onboarding checklist"
            checksId={['groupOwnerCheck', 'titleCheck']}
          />

          {OnboardingButton}
        </Grid>

        <Grid md={12}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        title="Domain Diagram"
        direction={Direction.TOP_BOTTOM}
        height={700}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

export default DomainPage;
