import React from 'react';
import { EntityLink } from '@backstage/catalog-model';
import { LinkButton } from '@backstage/core-components';
import { createStyles, makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
);

const LinkCard = ({ url, title }: EntityLink) => {
  const classes = useStyles();
  return (
    <LinkButton to={url} className={classes.link}>
      <Typography variant="button">{title}</Typography>
    </LinkButton>
  );
};

export default LinkCard;
