import React from 'react';
import { ClockConfig, HeaderWorldClock } from '@backstage/plugin-home';

const WorldClockHeader = () => {
  const clockConfigs: ClockConfig[] = [
    {
      label: 'NYC',
      timeZone: 'America/New_York',
    },
    {
      label: 'UK',
      timeZone: 'Europe/London',
    },
    {
      label: 'ESP',
      timeZone: 'Europe/Madrid',
    },
    {
      label: 'UTC',
      timeZone: 'UTC',
    },
  ];

  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  return (
    <HeaderWorldClock
      clockConfigs={clockConfigs}
      customTimeFormat={timeFormat}
    />
  );
};

export default WorldClockHeader;
