import React, { useState } from 'react';
import { Entity } from '@backstage/catalog-model';
import {
  WarningPanel,
  CodeSnippet,
  InfoCard,
  HelpIcon,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  CATALOG_FILTER_EXISTS,
  EntityRefLink,
} from '@backstage/plugin-catalog-react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useAsyncFn, useDebounce } from 'react-use';
import { makeStyles } from '@mui/styles';

const ICON_ANNOTATION = 'backstage/logo-url';
const FEATURED_ANNOTATION = 'backstage/featured';

const useCatalogStyles = makeStyles(() => ({
  root: {
    height: '100%',
    background:
      'linear-gradient(135.56deg, rgba(4, 10, 30, 0) -30.65%, rgba(108, 56, 255, 0.44) 96.51%) !important',
    borderRadius: '8px !important',
    transition: 'all .25s linear',
    textAlign: 'center',
    '&:hover': {
      boxShadow: '0px 0px 16px 0px rgba(255,255,255,0.8)',
    },
    '& svg': {
      fontSize: 80,
    },
    '& img': {
      height: 80,
      width: 80,
      objectFit: 'contain',
    },
  },
  subheader: {
    display: 'block',
    width: '100%',
  },
  link: {
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
}));

const CatalogFeaturedCards = () => {
  const catalogApi = useApi(catalogApiRef);
  const classes = useCatalogStyles();
  const [entities, setEntities] = useState<Entity[]>([]);
  const [activeFilters] = useState<string[]>([]);
  const [{ loading, error }, refresh] = useAsyncFn(
    async () => {
      const response = await catalogApi.getEntities({
        filter: {
          [`metadata.annotations.${ICON_ANNOTATION}`]: CATALOG_FILTER_EXISTS,
          [`metadata.annotations.${FEATURED_ANNOTATION}`]: 'true',
        },
      });
      setEntities(response.items);
    },
    [catalogApi],
    { loading: true },
  );
  useDebounce(refresh, 10);

  if (error) {
    return (
      <WarningPanel severity="error" title="Could not fetch catalog entities.">
        <CodeSnippet language="text" text={error.toString()} />
      </WarningPanel>
    );
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      {entities
        .filter(
          e =>
            activeFilters.length === 0 ||
            activeFilters.includes(e?.spec?.domain as string),
        )
        .map(e => (
          <Grid xs={12} sm={6} md={4} lg={3} xl={2} key={e.metadata.name}>
            <EntityRefLink entityRef={e} className={classes.link}>
              <InfoCard
                className={classes.root}
                title={
                  e.metadata.annotations?.[ICON_ANNOTATION] ? (
                    <img
                      src={e.metadata.annotations[ICON_ANNOTATION]}
                      alt={`${e.metadata.title || e.metadata.name} logo`}
                    />
                  ) : (
                    <HelpIcon />
                  )
                }
                subheader={
                  <div className={classes.subheader}>
                    {e.metadata.title || e.metadata.name}
                  </div>
                }
              >
                <Typography paragraph>{e.metadata.description}</Typography>
              </InfoCard>
            </EntityRefLink>
          </Grid>
        ))}
    </Grid>
  );
};

export default CatalogFeaturedCards;
