import { EntityAboutCard, EntityLayout } from '@backstage/plugin-catalog';
import React from 'react';

const LocationPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <EntityAboutCard />
    </EntityLayout.Route>
  </EntityLayout>
);

export default LocationPage;
