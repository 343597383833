import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { EntityLayout } from '@backstage/plugin-catalog';
import {
  EntityGroupProfileCard,
  EntityOwnershipCard,
  EntityMembersListCard,
} from '@backstage/plugin-org';
import EntityWarningContent from '../components/EntityWarningContent';

const GroupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {EntityWarningContent}

        <Grid xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>

        <Grid xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>

        <Grid xs={12}>
          <EntityMembersListCard />
        </Grid>

        {/* <Grid xs={12} md={6}>
          <EntityLinksCard />
        </Grid> */}
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export default GroupPage;
