import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const githubOnboardingPlugin = createPlugin({
  id: 'github-onboarding',
  routes: {
    root: rootRouteRef,
  },
});

export const GithubOnboardingPage = githubOnboardingPlugin.provide(
  createRoutableExtension({
    name: 'GithubOnboardingPage',
    component: () =>
      import('./components/GitHubReposPage/GitHubReposPage').then(
        m => m.GitHubReposPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
