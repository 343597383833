import {
  CostInsightsApi,
  ProductInsightsOptions,
  Alert,
} from '@internal/plugin-cost-insights';
import {
  Cost,
  Entity,
  Group,
  MetricData,
  Project,
  DateAggregation,
} from '@backstage-community/plugin-cost-insights-common';
import { Config } from '@backstage/config';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
/** @public  */

export class AwesomeCostInsightsClient implements CostInsightsApi {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
  private readonly config: Config;
  constructor(config: Config, discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.config = config;
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  async getLastCompleteBillingDate(): Promise<string> {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastCompleteBillingDate = yesterday.toISOString().slice(0, 10);
    return lastCompleteBillingDate;
  }

  /**
   * Get a list of groups the given user belongs to. These may be LDAP groups or similar
   * organizational groups. Cost Insights is designed to show costs based on group membership;
   * if a user has multiple groups, they are able to switch between groups to see costs for each.
   *
   * This method should be removed once the Backstage identity plugin provides the same concept.
   *
   * @param userId - The login id for the current user
   */

  async getUserGroups(): Promise<Group[]> {
    const groupNames = ['allsites'];
    const groups = groupNames.map((groupName: string) => ({
      id: groupName,
      name: groupName,
    }));
    return groups;
  }

  /**
   * Get a list of cloud billing entities that belong to this group (projects in GCP, AWS has a
   * similar concept in billing accounts). These act as filters for the displayed costs, users can
   * choose whether they see all costs for a group, or those from a particular owned project.
   *
   * @param group - The group id from getUserGroups or query parameters
   */

  async getGroupProjects(group: string): Promise<Project[]> {
    const jsonData = { group: group };

    const response = await this.fetchApi.fetch(
      `${await this.discoveryApi.getBaseUrl('proxy')}/costinsights/getProjectsFromGroup`,
      { method: 'POST', body: JSON.stringify(jsonData) },
    );

    const result = await response.json();

    const projects: Project[] = result.data.map(
      (projectName: string): Project => {
        return {
          id: projectName,
          name: projectName,
        };
      },
    );

    return projects;
  }

  /**
   * Get current cost alerts for a given group. These show up as Action Items for the group on the
   * Cost Insights page. Alerts may include cost-saving recommendations, such as infrastructure
   * migrations, or cost-related warnings, such as an unexpected billing anomaly.
   */

  async getAlerts(_group: string): Promise<Alert[]> {
    return [];
  }

  /**
   * Get aggregations for a particular metric and interval time frame. Teams
   * can see metrics important to their business in comparison to the growth
   * (or reduction) of a project or group's daily costs.
   *
   * @param _metric - A metric from the cost-insights configuration in app-config.yaml.
   * @param _intervals - An ISO 8601 repeating interval string, such as R2/P30D/2020-09-01
   *   https://en.wikipedia.org/wiki/ISO_8601#Repeating_intervals
   */

  // async getDailyMetricData (_metric: string, _intervals: string): Promise<MetricData> {
  //  return {
  //    id: 'remove-me',
  //    format: 'number',
  //    aggregation: [],
  //    change: {
  //      ratio: 0,
  //      amount: 0,
  //    },
  //  };
  // }

  async getDailyMetricData(
    _metric: string,
    _intervals: string,
    _project: string,
  ): Promise<MetricData> {
    const jsonData = {
      Metric: _metric,
      Interval: _intervals,
      Project: _project,
    };
    const backendUrl = this.config.getString('backend.baseUrl');

    if (_intervals === '0') {
      // eslint-disable-next-line no-param-reassign
      _intervals = '7';
    }

    if (_project === null) {
      return {
        id: 'remove-me',
        format: 'number',
        aggregation: [],
        change: {
          ratio: 0,
          amount: 0,
        },
      };
    }
    const response = await this.fetchApi.fetch(
      `${backendUrl}/api/proxy/costinsights/getProjectMetrics`,
      { method: 'POST', body: JSON.stringify(jsonData) },
    );
    const result = await response.json();
    const aggregationData = result.data;
    const aggregation: DateAggregation[] = aggregationData.map((item: any) => ({
      date: item.date,
      amount: Number(item.amount),
    }));
    const firstValue = aggregation[0]?.amount || 0;
    const lastValue = aggregation[aggregation.length - 1]?.amount || 0;
    const absoluteChange = lastValue - firstValue;

    let ratioChange: number | undefined = undefined;
    if (firstValue !== 0) {
      ratioChange = absoluteChange / firstValue;
    }

    const metricData: MetricData = {
      id: 'engagement',
      format: 'number',
      aggregation: aggregation,
      change: {
        amount: absoluteChange,
        ratio: ratioChange,
      },
    };

    return metricData;
  }

  /**
   * Get daily cost aggregations for a given group and interval time frame.
   *
   * The return type includes an array of daily cost aggregations as well as statistics about the
   * change in cost over the intervals. Calculating these statistics requires us to bucket costs
   * into two or more time periods, hence a repeating interval format rather than just a start and
   * end date.
   *
   * The rate of change in this comparison allows teams to reason about their cost growth (or
   * reduction) and compare it to metrics important to the business.
   *
   * @param _group - The group id from getUserGroups or query parameters
   * @param _intervals - An ISO 8601 repeating interval string, such as R2/P30D/2020-09-01
   *   https://en.wikipedia.org/wiki/ISO_8601#Repeating_intervals
   */

  async getGroupDailyCost(_group: string, _intervals: string): Promise<Cost> {
    return {
      id: 'remove-me',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  /**
   * Get daily cost aggregations for a given billing entity (project in GCP, AWS has a similar
   * concept in billing accounts) and interval time frame.
   *
   * The return type includes an array of daily cost aggregations as well as statistics about the
   * change in cost over the intervals. Calculating these statistics requires us to bucket costs
   * into two or more time periods, hence a repeating interval format rather than just a start and
   * end date.
   *
   * The rate of change in this comparison allows teams to reason about the project's cost growth
   * (or reduction) and compare it to metrics important to the business.
   *
   * @param project - The project id from getGroupProjects or query parameters
   * @param intervals - An ISO 8601 repeating interval string, such as R2/P30D/2020-09-01
   *   https://en.wikipedia.org/wiki/ISO_8601#Repeating_intervals
   */

  async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
    const jsonData = { Project: project, Interval: intervals };
    const backendUrl = this.config.getString('backend.baseUrl');
    const response = await this.fetchApi.fetch(
      `${backendUrl}/api/proxy/costinsights/getProjectCost`,
      { method: 'POST', body: JSON.stringify(jsonData) },
    );
    const result = await response.json();
    let costs = result.data.costs;

    if (!costs || costs.length === 0) {
      costs = [{ date: '1970-01-01', amount: 0 }];
    }

    const createCostObject = (
      categoryCosts: any[],
      categoryId: string,
    ): Cost => ({
      id: categoryId,
      aggregation: categoryCosts.map((item: any) => ({
        date: item.date,
        amount: item.amount,
      })),
      change: undefined,
      trendline: {
        slope: 0,
        intercept: 0,
      },
      groupedCosts: undefined,
    });

    const kubecostCosts = createCostObject(
      costs.kubecost || [{ date: '1970-01-01', amount: 0 }],
      'kubecost',
    );
    const awsCosts = createCostObject(
      costs.AWS || [{ date: '1970-01-01', amount: 0 }],
      'AWS',
    );
    const contentfulCosts = createCostObject(
      costs.contentful || [{ date: '1970-01-01', amount: 0 }],
      'contentful',
    );
    const cloudflareCosts = createCostObject(
      costs.cloudflare || [{ date: '1970-01-01', amount: 0 }],
      'cloudflare',
    );

    const groupedCosts: Record<string, Cost[]> = {
      type: [kubecostCosts, awsCosts, contentfulCosts, cloudflareCosts],
    };

    function aggregateCostsByDate(
      costItems: { date: string; amount: number }[],
    ): { date: string; amount: number }[] {
      const costMap: Record<string, number> = {};
      costItems.forEach(item => {
        if (costMap[item.date]) {
          costMap[item.date] += item.amount;
        } else {
          costMap[item.date] = item.amount;
        }
      });
      return Object.keys(costMap).map(date => ({
        date,
        amount: costMap[date],
      }));
    }

    const totalCosts = aggregateCostsByDate([
      ...(costs.kubecost || []),
      ...(costs.AWS || []),
      ...(costs.contentful || []),
      ...(costs.cloudflare || []),
    ]);

    const costData: Cost = {
      id: result.data.projectId || 'adcom',
      aggregation: totalCosts.map(item => ({
        date: item.date,
        amount: item.amount,
      })),
      change: undefined,
      trendline: {
        slope: 0,
        intercept: 0,
      },
      groupedCosts: groupedCosts,
    };

    return costData;
  }

  /**
   * Get daily cost aggregations for a given catalog entity and interval time frame.
   *
   * The return type includes an array of daily cost aggregations as well as statistics about the
   * change in cost over the intervals. Calculating these statistics requires us to bucket costs
   * into two or more time periods, hence a repeating interval format rather than just a start and
   * end date.
   *
   * The rate of change in this comparison allows teams to reason about their cost growth (or
   * reduction) and compare it to metrics important to the business.
   *
   * Note: implementing this is only required when using the `EntityCostInsightsContent` extension.
   *
   * @param _catalogEntityRef - A reference to the catalog entity, as described in
   *   https://backstage.io/docs/features/software-catalog/references
   * @param _intervals - An ISO 8601 repeating interval string, such as R2/P30D/2020-09-01
   *   https://en.wikipedia.org/wiki/ISO_8601#Repeating_intervals
   */

  async getCatalogEntityDailyCost(
    _catalogEntityRef: string,
    _intervals: string,
  ): Promise<Cost> {
    return {
      id: 'remove-me',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  /**
   * Get cost aggregations for a particular cloud product and interval time frame. This includes
   * total cost for the product, as well as a breakdown of particular entities that incurred cost
   * in this product. The type of entity depends on the product - it may be deployed services,
   * storage buckets, managed database instances, etc.
   *
   * If project is supplied, this should only return product costs for the given billing entity
   * (project in GCP).
   *
   * The time period is supplied as a Duration rather than intervals, since this is always expected
   * to return data for two bucketed time period (e.g. month vs month, or quarter vs quarter).
   *
   * @param _options - Options to use when fetching insights for a particular cloud product and
   *                interval time frame.
   */

  async getProductInsights(_options: ProductInsightsOptions): Promise<Entity> {
    return {
      id: 'remove-me',
      aggregation: [0, 0],
      change: {
        ratio: 0,
        amount: 0,
      },
      entities: {},
    };
  }
}
