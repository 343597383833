import React from 'react';
import { Route } from 'react-router';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { EntityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { oktaAuthApiRef } from '@backstage/core-plugin-api';
import { customTheme } from './components/theme/customTheme';
import { UnifiedThemeProvider } from '@backstage/theme';
import { CostInsightsPage } from '@internal/plugin-cost-insights';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { CustomExplorePage } from './components/explore/CustomExplorePage';
import { GithubOnboardingPage } from '@internal/plugin-github-onboarding';
import { HelmDashboardPage } from '@internal/backstage-plugin-helm-dashboard';
import { HelmReleasePage } from '@internal/backstage-plugin-helm-dashboard';
import { CatalogImportPage } from '@backstage/plugin-catalog-import';
import { CloudcraftInfraPage } from '@internal/backstage-plugin-cloudcraft-infra';
import { createTranslationMessages } from '@backstage/core-plugin-api/alpha';
import { kubernetesReactTranslationRef } from '@backstage/plugin-kubernetes-react/alpha';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';

const app = createApp({
  __experimentalTranslations: {
    resources: [
      createTranslationMessages({
        ref: kubernetesReactTranslationRef,
        messages: {
          'podDrawer.buttons.delete': 'Restart Pod',
        },
      }),
    ],
  },
  apis,
  themes: [
    {
      id: 'customTheme',
      title: 'Custom Theme',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={customTheme} children={children} />
      ),
    },
  ],
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        provider={{
          id: 'okta',
          title: 'Okta',
          message: 'Sign in using OKTA',
          apiRef: oktaAuthApiRef,
        }}
      />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

// https://github.com/backstage/backstage/issues/25582
// const overrideThemeOptions: Partial<ThemeOptions> = {
//   palette: {
//     background: {
//       default: '#1D2335',
//     },
//   },
// };

const importRoute =
  window.location.hostname !== 'backstage.net.management' ? (
    <Route path="/catalog-import" element={<CatalogImportPage />} />
  ) : null;

const routes = (
  <FlatRoutes>
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>

    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>

    <Route path="/explore" element={<ExplorePage />}>
      {CustomExplorePage}
    </Route>

    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {EntityPage}
    </Route>

    <Route path="/catalog-graph" element={<CatalogGraphPage />} />

    {importRoute}

    <Route path="/api-docs" element={<ApiExplorerPage />} />

    {/* https://github.com/backstage/backstage/issues/25582 */}
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
      // element={
      //   <TechDocsReaderPage overrideThemeOptions={overrideThemeOptions} />
      // }
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>

    <Route path="/helm/dashboard" element={<HelmDashboardPage />} />
    <Route
      path="/helm/namespaces/:namespace/releases/:release"
      element={<HelmReleasePage />}
    />

    <Route path="/cloudcraft-infra" element={<CloudcraftInfraPage />} />

    <Route path="/github-onboarding" element={<GithubOnboardingPage />} />

    <Route path="/cost-insights" element={<CostInsightsPage />} />

    <Route
      path="/corg-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="corg"
          pageTitle="CORG Tech Radar"
        />
      }
    />
    <Route
      path="/data-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="data"
          pageTitle="DATA Tech Radar"
        />
      }
    />
    <Route
      path="/sre-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="sre"
          pageTitle="SRE Tech Radar"
        />
      }
    />
    <Route
      path="/gaming-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="gaming"
          pageTitle="Gaming Tech Radar"
        />
      }
    />
    <Route
      path="/sports-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="sports"
          pageTitle="Sports Tech Radar"
        />
      }
    />
    <Route
      path="/phoenix-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="phoenix"
          pageTitle="Phoenix Tech Radar"
        />
      }
    />
    <Route
      path="/poker-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="poker"
          pageTitle="Poker - Replay Tech Radar"
        />
      }
    />
    <Route
      path="/cardschat-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="cardschat"
          pageTitle="CardsChat Tech Radar"
        />
      }
    />
    <Route
      path="/moneywise-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="moneywise"
          pageTitle="MoneyWise Tech Radar"
        />
      }
    />
    <Route
      path="/covers-tech-radar"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="covers"
          pageTitle="Covers Tech Radar"
        />
      }
    />

    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Backstage Onboarding',
              filter: entity =>
                (entity?.metadata?.tags?.includes('backstage') &&
                  entity?.metadata?.tags?.includes('onboarding')) ??
                false,
            },
            {
              title: 'Services',
              filter: entity =>
                entity?.metadata?.tags?.includes('service') ?? false,
            },
          ]}
        />
      }
    />

    <Route path="/devtools" element={<DevToolsPage />}>
      {customDevToolsPage}
    </Route>

    {/* This is needed only for the above devtools plugin, there is no sidebar link in the Root.tsx file for this */}
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />

    <Route path="/settings" element={<UserSettingsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
