import React from 'react';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useFetchUsers } from '../../hooks';
import { User } from '../../types';
import { UserCards } from '../UserCards';

export const FetchSchedule = ({ scheduleId }: { scheduleId: string }) => {
  const { value, loading, error } = useFetchUsers(scheduleId);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return <UserCards users={value as User[]} />;
};
