import React from 'react';
import { sidebarConfig, useSidebarOpenState } from '@backstage/core-components';
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

const useSidebarLogoStyles = makeStyles({
  rootClosed: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  rootOpen: {
    width: '100%',
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  linkClosed: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 8,
  },
  linkOpen: {
    width: '100%',
    marginLeft: -22,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();
  return (
    <div className={isOpen ? classes.rootOpen : classes.rootClosed}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={isOpen ? classes.linkOpen : classes.linkClosed}
        aria-label="Home"
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export default SidebarLogo;
