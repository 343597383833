import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import OverviewContent from '../components/OverviewContent';

const OverviewPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewContent}
    </EntityLayout.Route>
  </EntityLayout>
);

export default OverviewPage;
