import {
  createPlugin,
  createRoutableExtension,
  createComponentExtension,
} from '@backstage/core-plugin-api';

import { detailsRouteRef, rootRouteRef } from './routes';

export const helmDashboardPlugin = createPlugin({
  id: 'helm-dashboard',
  routes: {
    index: rootRouteRef,
    details: detailsRouteRef,
  },
});

export const HelmDashboardPage = helmDashboardPlugin.provide(
  createRoutableExtension({
    name: 'HelmDashboardPage',
    component: () => import('./components').then(m => m.HelmDashboardPage),
    mountPoint: rootRouteRef,
  }),
);

export const HelmIcon = helmDashboardPlugin.provide(
  createComponentExtension({
    name: 'HelmIcon',
    component: {
      lazy: () => import('./components').then(m => m.HelmIcon),
    },
  }),
);

export const HelmReleasePage = helmDashboardPlugin.provide(
  createComponentExtension({
    name: 'HelmReleasePage',
    component: {
      lazy: () => import('./components').then(m => m.HelmReleasePage),
    },
  }),
);
