import React from 'react';
import {
  EntityAboutCard,
  EntityLayout,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import Grid from '@mui/material/Unstable_Grid2';
import EntityWarningContent from '../components/EntityWarningContent';
import { EntityTechInsightsScorecardCard } from '@backstage-community/plugin-tech-insights';
import {
  EntityProvidingComponentsCard,
  EntityConsumingComponentsCard,
  EntityApiDefinitionCard,
} from '@backstage/plugin-api-docs';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import LinkCards from '../../linkCard/LinkCards';
import { hasLinks } from '../../utils';
import OnboardingButton from '../components/OnboardingButton';

const ApiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {EntityWarningContent}

        <Grid md={6}>
          <EntitySwitch>
            <EntitySwitch.Case if={hasLinks}>
              <Grid xs={12}>
                <EntityAboutCard variant="gridItem" />
              </Grid>
            </EntitySwitch.Case>

            <EntitySwitch.Case>
              <EntityAboutCard variant="gridItem" />
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>

        <Grid md={6} xs={12}>
          <EntityTechInsightsScorecardCard
            title="API onboarding checklist"
            checksId={[
              'groupOwnerCheck',
              'titleCheck',
              'apiHasSystem',
              'apiHasProvidedBy',
            ]}
          />

          {OnboardingButton}
        </Grid>

        <EntitySwitch>
          <EntitySwitch.Case if={hasLinks}>
            <Grid md={6} xs={12}>
              <LinkCards />
            </Grid>

            <Grid md={6} xs={12}>
              <EntityCatalogGraphCard variant="gridItem" height={400} />
            </Grid>
          </EntitySwitch.Case>

          <EntitySwitch.Case>
            <Grid md={12} xs={12}>
              <EntityCatalogGraphCard variant="gridItem" height={400} />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <Grid container md={12}>
          <Grid md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export default ApiPage;
