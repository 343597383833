import { HorizontalScrollGrid } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import LinkCard from './LinkCard';
import { InfoCard } from '@backstage/core-components';

const LinkCards = () => {
  const { entity } = useEntity();

  if (!entity.metadata.links) {
    return null;
  }

  return (
    <InfoCard title="Links">
      <HorizontalScrollGrid>
        {entity.metadata.links.map(link => (
          <Grid>
            <LinkCard url={link.url} title={link.title} />
          </Grid>
        ))}
      </HorizontalScrollGrid>
    </InfoCard>
  );
};

export default LinkCards;
