import {
  EntityLayout,
  EntitySwitch,
  isComponentType,
} from '@backstage/plugin-catalog';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  BaseEntityRoutes,
  DependenciesRoute,
  TestingSuitesRoutes,
  KubernetesRoute,
} from '../routes/Routes';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';

const ComponentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      <EntityLayout>
        {BaseEntityRoutes}

        <EntityLayout.Route path="/api" title="API">
          <Grid container spacing={3} alignItems="stretch">
            <Grid xs={12}>
              <EntityProvidedApisCard />
            </Grid>

            <Grid xs={12}>
              <EntityConsumedApisCard />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        {DependenciesRoute}

        {TestingSuitesRoutes}

        {KubernetesRoute}
      </EntityLayout>
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      <EntityLayout>
        {BaseEntityRoutes}

        {DependenciesRoute}

        {TestingSuitesRoutes}

        {KubernetesRoute}
      </EntityLayout>
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EntityLayout>
        {BaseEntityRoutes}

        {TestingSuitesRoutes}

        {KubernetesRoute}
      </EntityLayout>
    </EntitySwitch.Case>
  </EntitySwitch>
);

export default ComponentPage;
