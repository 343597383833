import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const cloudcraftInfraPlugin = createPlugin({
  id: 'cloudcraft-infra',
  routes: {
    root: rootRouteRef,
  },
});

export const CloudcraftInfraPage = cloudcraftInfraPlugin.provide(
  createRoutableExtension({
    name: 'CloudcraftInfraPage',
    component: () => import('./components').then(m => m.CloudcraftDiagramsPage),
    mountPoint: rootRouteRef,
  }),
);

export const CloudcraftIcon = cloudcraftInfraPlugin.provide(
  createComponentExtension({
    name: 'CloudcraftIcon',
    component: {
      lazy: () => import('./components').then(m => m.CloudcraftIcon),
    },
  }),
);
