import React from 'react';
import {
  CatalogKindExploreContent,
  DomainExplorerContent,
  ExploreLayout,
  GroupsExplorerContent,
} from '@backstage-community/plugin-explore';
import { Direction } from '@backstage/plugin-catalog-graph';

export const CustomPage = () => {
  return (
    <ExploreLayout
      title="Explore the Legend ecosystem"
      subtitle="Browse our ecosystem"
    >
      <ExploreLayout.Route path="domains" title="Domains">
        <DomainExplorerContent />
      </ExploreLayout.Route>

      <ExploreLayout.Route path="groups" title="Groups">
        <GroupsExplorerContent direction={'BT' as Direction} />
      </ExploreLayout.Route>

      <ExploreLayout.Route path="systems" title="Systems">
        <CatalogKindExploreContent kind="system" />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const CustomExplorePage = <CustomPage />;
