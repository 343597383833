import React from 'react';
import logo from '../../assets/sonarcloud.png';

const SonarcloudLogo = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={logo}
        style={{ width: '80%', padding: '0 5px' }}
        alt="Sonarcloud logo"
      />
    </div>
  );
};

export default SonarcloudLogo;
