import React from 'react';
import logo from '../../assets/jira.png';

const JiraLogo = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={logo}
        style={{ width: '80%', padding: '0 5px' }}
        alt="Jira logo"
      />
    </div>
  );
};

export default JiraLogo;
