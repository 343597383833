import {
  createUnifiedTheme,
  createBaseThemeOptions,
  genPageTheme,
  palettes,
  shapes,
  defaultTypography,
} from '@backstage/theme';

export const customTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      background: {
        default: '#040A1E',
        paper: '#363B4B',
      },
      status: {
        ok: '#71CF88',
        warning: '#FFB84D',
        error: '#F84C55',
        running: '#3488E3',
        pending: '#FEF071',
        aborted: '#9E9E9E',
      },
      bursts: {
        fontColor: '#FEFEFE',
        slackChannelText: '#DDDDDD',
        backgroundColor: {
          default: '#7C3699',
        },
        gradient: {
          linear: 'linear-gradient(-137deg, #4BB8A5 0%, #187656 100%)',
        },
      },
      primary: {
        main: '#FFFFFF',
        dark: '#82BAFD',
      },
      secondary: {
        main: '#4B2CFF',
      },
      banner: {
        info: '#4B2CFF',
        error: '#E43748',
        text: '#FFFFFF',
        link: '#000000',
        closeButtonColor: '#FFFFFF',
        warning: '#FFA219',
      },
      border: '#E6E6E6',
      textContrast: '#FFFFFF',
      textVerySubtle: '#727272',
      textSubtle: '#CCCCCC',
      highlight: '#FFFBCC',
      errorBackground: '#FFEBEE',
      warningBackground: '#F59B23',
      infoBackground: '#EBF5FF',
      errorText: '#CA001B',
      infoText: '#004E8A',
      warningText: '#000000',
      linkHover: '#82BAFD',
      link: '#9CC9FF',
      gold: '#FFD600',
      navigation: {
        background: '#040A1E',
        indicator: '#FFFFFF',
        color: '#FFFFFF',
        selectedColor: '#FFFFFF',
        navItem: {
          hoverBackground: '#6C38FF',
        },
        submenu: {
          background: '#040A1E',
        },
      },
      pinSidebarButton: {
        icon: '#404040',
        background: '#BDBDBD',
      },
      tabbar: {
        indicator: '#9BF0E1',
      },
    },
  }),
  defaultPageTheme: 'home',
  typography: {
    ...defaultTypography,
    fontFamily: '"Sora", sans-serif',
  },
  /* Headers pages */
  pageTheme: {
    home: genPageTheme({
      colors: ['rgba(108, 56, 255, 1)', 'rgba(108, 56, 255, 1)'],
      shape: shapes.wave,
    }),
    tool: genPageTheme({
      colors: ['rgba(108, 56, 255, 1)', 'rgba(108, 56, 255, 1)'],
      shape: shapes.wave,
    }),
  },
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: {
          width: 'auto',
          background: 'rgba(75,44,255,0.7)',
          borderBottom: '0px solid #E7EBF0',
          boxShadow: 'none',
        },
        title: {
          color: '#FFFFFF',
          fontSize: 32,
          fontFamily: '"Sora", sans-serif',
        },
        subtitle: () => ({
          color: '#FFFFFF',
        }),
      },
    },
    BackstageContent: {
      styleOverrides: {
        root: {
          background: '#1D2335',
        },
      },
    },
    BackstageEmptyState: {
      styleOverrides: {
        root: {
          background: '#1D2335',
          backgroundColor: '#1D2335',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          position: 'absolute',
          width: '35px',
          height: '14px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#363B4B',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#000000 !important',
          backgroundColor: '#FFFFFF !important',
          border: '1px solid #FFFFFF',
          borderRadius: '200px !important',
          transition: 'all .3s cubic-bezier(1,0,0.58,1)',
          '&:hover': {
            color: '#000000 !important',
            border: '1px solid #F8FB67',
            backgroundColor: '#F8FB67 !important',
          },
        },
      },
    },
  },
});
