import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const githubEntityReleasePlugin = createPlugin({
  id: 'github-entity-release',
  routes: {
    root: rootRouteRef,
  },
});

export const GithubEntityRelease = githubEntityReleasePlugin.provide(
  createRoutableExtension({
    name: 'GithubEntityRelease',
    component: () =>
      import('./components/ReleasesPage').then(m => m.ReleasesPage),
    mountPoint: rootRouteRef,
  }),
);
