import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography';
import { Users } from '../../types';

export const UserCards = ({ users }: Users) => {
  return users
    .filter(user => {
      return !user?.deleted_at && user?.deleted_at !== null;
    })
    .map(user => {
      return (
        <div
          style={{
            display: 'flex',
            width: '100%',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          key={user.name}
        >
          <CircleIcon
            style={{
              height: '15px',
              fill: '#4caf50',
              marginBottom: 'auto',
            }}
          />
          <Typography variant="h4" style={{ paddingLeft: '5px' }}>
            {user.name
              .split('.')
              .map(word => {
                return word[0].toUpperCase() + word.substring(1);
              })
              .join(' ')}
          </Typography>
        </div>
      );
    });
};
