import React from 'react';
import logo from '../../assets/github.png';

const GitHubLogo = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img
        src={logo}
        style={{ width: '80%', padding: '0 5px' }}
        alt="GitHub logo"
      />
    </div>
  );
};

export default GitHubLogo;
